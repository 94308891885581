import React, {Fragment} from 'react';
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import SEO from "../components/seo";
import Layout from "../components/layout";
import TopBunerProduct from '../components/top-buner/top-baner-product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {
  faYoutube,
  faVk,
  faInstagram,
  faFacebook
} from '@fortawesome/free-brands-svg-icons';

import OfficeOnMap from '../components/office-on-map/office-on-map'
import GalleryInstagram from '../components/gallery-instagram/gallery-instagram';
import FormQuickOrder from '../components/form-quick-oreder/form-quick-order';





const Contacts = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Контакты', url:'#', status:true}]
  return (
    <Fragment>
      <SEO  title="Телефоны, почта, мессенджеры для связи с племенным центром Petersdorf">
     </SEO>
     <Layout>
       
     <TopBunerProduct TopImage={''}  
                      titileH1={'Контакты Petersdorf'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
        <Container>
           <Row className="mt-5">
            <Col md="6" sm="12">
            <h3 className="font-gold" id="mail-name">Свяжитесь с нами:</h3>
            <hr className="m-5 blue-color"/>
           <p>
           <FontAwesomeIcon
                icon={faPhone}
                color="#01717d"
                size="lg"
            />
            {' '}<a rel="nofollow" className="pl-3 font-green" href="tel: +79814572668"> +7(981)4572668</a>
            </p>
            <p>
            <FontAwesomeIcon
            icon={faEnvelope}
            color="#01717d"
            size="lg"
            />
            {' '}<span className="pl-3 font-green">__</span>
            </p>
           
           </Col>
                
           <Col md="6" sm="12">
            <h3 className="font-gold">Подписывайтесь на наши каналы в соцсетях:</h3>
            <hr className="m-3 blue-color"/>
            <p>
            <FontAwesomeIcon
            icon={faInstagram}
            color="#01717d"
            size="lg"
            />
            {' '}<a target="_blank" className="pl-3 font-green" href="https://www.instagram.com/plemcentre/">@plemcentre</a>
            </p>
            <p>
            <FontAwesomeIcon
            icon={faVk}
            color="#01717d"
            size="lg" 
            />
            {' '}<a target="_blank" className="pl-3 font-green" href="https://vk.com/plemcentre">VK "Петерсдорф"</a>
            </p>
            <p>
            <FontAwesomeIcon icon={faFacebook}
            color="#01717d"
            size="lg" />
            {' '}<a target="_blank" className="pl-3 font-green" href="https://www.facebook.com/plemcentre/">Petersdorf</a>
            </p>
            </Col>
            </Row>
           <hr className="m-5 font-green"/>
    </Container>
    <FormQuickOrder />
    <OfficeOnMap></OfficeOnMap>
     </Layout>
    </Fragment>
  );
};

export default Contacts;